$(document).ready(function() {
    "use strict";

    $(".collections-search__input").keypress(function(e){
        var q = $(this).val();
        if (q.length && e.which==13) {
            var search_url = 'https://catalog.libfl.ru/Search/Results?lookfor=' + encodeURI(q);
            window.location = search_url;
        }
    });

    $(".collections-search__button").click(function(e){
        e.preventDefault();
        var q = $(".collections-search__input").val();

        var search_url = 'https://catalog.libfl.ru/Search/Results?lookfor=' + encodeURI(q);
        window.location = search_url;
    });

    $.ajax({
        url: "/ajax/main_Books_collections",
        type: "get",
        dataType: "html",
        success: function(data) {
            var append = data || "<div class='collection_empty'>По вашему запросу ничего не найдено.</div>";
            $("#collections").html(append);

            $('#collections').slick({
                arrows: true,
                centerMode: true,
                centerPadding: '85px',
                slidesToShow: 2,
                responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        centerPadding: '35px',
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '35px',
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false
                    }
                }
                ]
            });
        }
    });

});
